html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}
.body{
  font-family: 'Roboto', serif;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.main{
  background-color: rgb(46, 46, 46);
}
.menu-wrapper{
  line-height: 70px;
}
.header {
  /* background-color: rgba(77, 77, 77, 0.5); */
  /* box-shadow: 0px 1px 4px 0 rgba(200, 168, 233, 0.2); */
  position: fixed;
  width: 100%;
  z-index: 3;
  color:white;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}
.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  color:white;
}
.header li a {
  font-size:20px;
  display: block;
  padding: 38px 20px;
  text-decoration: none;
  color:white;
}
.header li a:hover,
.header .menu-btn:hover {
  background-color: rgba(255, 255, 255, 0.187);
  color:white;
  text-shadow: 0px 0px 4px white;
}
.header .logo {
  color:white;
  display: block;
  float: left;
  font-size: 28px;
  font-weight: 700;
  text-decoration: none;
  padding-left: 20px;
}
.header .logo:hover {
  text-shadow: 0px 0px px white;
}
.header .menu {
  clear: both;
  max-height: 0;
  transition: .2s ease-out;
  color:white;
}
/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: block;
  padding: 35px 20px;
  line-height: 1;
  position: relative;
  user-select: none;
  float: right;
  color:white;
}
.header .menu-icon:hover{
  background-color: rgba(255, 255, 255, 0.3);
  color:white;
  text-shadow: 0px 0px 4px white;
}
.header .menu-icon .navicon {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  transition: .2s ease-out;
  width: 18px;
}
.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: white;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
.header .menu-icon .navicon:before {
  top: 7px;
}
.header .menu-icon .navicon:after {
  top: -7px;
}
/* menu btn */
.header .menu-btn {
  display: none;
  
}
.header .menu-btn:checked ~ .menu {
  max-height: 340px;
  background-color: rgb(26, 26, 26);
}
.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

h3{
  margin-bottom: 10px;
  margin-top: 0;
}
/* #top{
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.2);
  
  opacity: 0.9;
  height:100vh;
  z-index:0;
} */
#opening{
  margin: 0 auto;
  min-height:100vh;
  background-image:url(https://images.unsplash.com/photo-1516646085441-e1719f13aa3e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2300);
  background-position: 40% 70%;
  background-size: cover;
  backdrop-filter: overlay;
}
#opening-content{
  color:white;
  margin: 0 auto;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 800px;
  justify-content:flex-start;
  align-items:flex-start;
  margin-left: 180px;
  padding: 0 10px;
}
#jacky{
  text-align: left;
  font-family:  'Roboto',cursive, sans-serif;
  margin-top: 200px;
  font-size:90px;
  font-weight: 700;
  color:white;
  text-shadow: 0 0 20px rgb(208, 248, 255);
}
#subtitle{
  display: flex;
  flex-direction: row;
  margin-bottom: 0 auto 80px auto;
  font-size: 24px;
  text-shadow: 0 0 20px rgb(208, 248, 255);
  justify-items: center;
}
#first-intro{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 220px;
}
.self-intro{
  font-family: 'Roboto',cursive, sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
}


.section-paragraph{
  max-width: 700px;
}
.dev{
  background: -webkit-linear-gradient(180deg,rgb(43, 191, 255), rgb(135, 75, 255), rgb(243, 107, 255));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-decoration: none;
}
.dev:hover{
  cursor: pointer;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}
.uxui{
  background: -webkit-linear-gradient(0deg,rgb(43, 191, 255), rgb(126, 83, 255), rgb(243, 107, 255));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-decoration: none;
}
.uxui:hover{
  cursor: pointer;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}
.down-arrow:hover{
  cursor: pointer;
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
  -webkit-filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
}
#about{
  background-color:rgb(97, 76, 99);
  background-image:url(https://images.unsplash.com/photo-1468971050039-be99497410af?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1190&q=80);
  background-position: 40% 50%;
  background-size: cover;
  background-blend-mode: overlay;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 0;
  margin-top: 0;
}
#about-content{
  color:white;
  font-weight: 400;
  margin: 120px;
  max-width: 700px;
  min-height: 45vh;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 30px 50px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#about-top-part{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px 0 10px 0;
}
#about-me{
  display: flex;
  flex-grow: 8;
  margin: 0 auto;
  font-size: 24px;
}
.two-buttons{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow:1;
  justify-content: center;
  align-items: center;
  font-size:20px;
  color:white;
}
.link-img{
  margin: 0px 10px;
}
.link-img:hover{
  cursor: pointer;
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
  -webkit-filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
}
.about-paragraph{
  text-align: left;
}
#journey{
  max-width: 600px;
  filter: drop-shadow(0 1px 5px rgba(118, 118, 118, 0.9));
}
#se, #ux{
  background-color:rgb(34, 32, 37);
  background-image: linear-gradient(to right, rgb(133, 78, 196),rgb(93, 150, 212));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  padding: 20px;
}
#ux{
  background-image: linear-gradient(to right, rgb(58, 70, 160),rgb(205, 133, 211));
}
#se-content, #ux-content{
  margin:20px;
}
#se-title, #ux-title{
  margin-top: 10px;
  color:white;
  font-size: 24px;
}
.map-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.project-card{
  width: 300px;
  height: 500px;
  border-radius: 8px;
  padding: 0 0 10px 0;
  margin: 30px 4px;
  background-color: rgba(255, 255, 255, 0.15);  
  backdrop-filter: blur(5px);
}

.link-button{
  color:white;
  font-size: 20px;
  border: 1px solid white;
  border-radius: 24px;
  padding: 6px 20px;
  min-width:80px;
  text-decoration: none;
  margin: 20px 10px;
  align-items: center;
  justify-items: center;

}
.link-button:hover{  
  box-shadow: 0px 0px 20px white;
  text-shadow: 0px 0px 20px white;
  background-color: rgba(0, 0, 0, 0.1);  
  backdrop-filter: blur(5px);
}
.card-img{
  height: 40vh;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.project-name{
  font-size: 20px;
  font-weight: 700;
  margin: 15px;
  color:white;
}
.project-description{
  margin: 8px 20px;
  color:white;
}
#contact{
  background-color:rgb(89, 101, 131);
  background-image: url("https://images.unsplash.com/photo-1581955520764-2e4ebec80523?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80");
  background-position: 40%;
  background-size: cover;
  background-blend-mode: overlay;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  padding: 20px;
}

#contact-content{
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color:white;
  background-color: rgba(255, 255, 255, 0.15);  
  backdrop-filter: blur(5px);
  padding: 40px 50px;
  border-radius: 10px;
  align-items: center;
}
#contact-button{
  padding: 10px;
  width: 200px;
}

/* Responsive */
@media only screen and (max-width: 1270px){
  #first-intro{
    margin-top: 200px;
    padding: 20px 40px;
    background-color: rgba(165, 177, 187, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
}
@media only screen and (max-width: 980px){
  .header ul{
    color:white;
  }  
  .header li a {
    padding: 15px;
    border-bottom: 1px solid rgba(45, 45, 45, 0.2);
    color:white;
  }
  #opening-content{
    margin: 0 auto;
    padding: 10px 20px;
  }
  #jacky{
    font-size:80px;
  }
  #first-intro{
    margin-top:100px;
    padding: 20px 40px;
    background-color: rgba(165, 177, 187, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
  #about-content{
    width: 70vw;
    margin: 20px;
    height: auto;
    align-self: center;
  }
  #journey{
    display: none;
  }
}
@media only screen and (max-width: 779px){
  #jacky{
    margin-top:120px;
  }
  #opening{
    height: 100vh;
  }
}
@media only screen and (max-width: 590px){
  #jacky{
    font-size: 60px;
  }
}
@media only screen and (max-width: 375px) and (max-height: 600px) {
  #jacky{
    font-size: 40px;
    margin-top: 100px;
    margin-bottom: 20px;
  }
  #subtitle{
    text-align: left;
    font-size: 20px;
  }
  #first-intro{
    padding: 10px;
  }
  .self-intro{
    font-size:18px;
  }
}
@media only screen and (max-width: 375px) and (min-height: 800px){
  .self-intro{
    font-size:24px;
  }
  #jacky{
    font-size: 60px;
    margin-top: 150px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 780px) {
  .menu-wrapper{
    /* height: 70px; */
    line-height: 70px;
    width: 100%;
  }
  .header li {
    float: left;
  }
  .header .logo{
    padding: 0px 20px;
  }
  .header li a {
    padding: 0px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}